<template>
  <Card class="center">
    <h5>Fast geschafft...</h5>
    <div v-if="token" class="my-1">
      <p>
        Ihr Account wird aktiviert...
      </p>
      <p>
        Bitte haben sie einen Moment Geduld.
      </p>
    </div>
    <div v-else>
      <p>
        Bitte <b>bestätigen Sie Ihre E-Mail-Adresse</b>, um die Registrierung abzuschließen.<br><br>
        Klicken Sie dazu einfach auf den Link, den wir Ihnen an die angegebene E-Mail-Adrese geschickt haben.
      </p>
      <p>
        Sollten Sie keine Nachricht erhalten haben, überprüfen Sie bitte auch Ihrem Spam-Ordner.
        <span v-if="email">Alternativ können Sie sich die Bestätigungs-E-Mail <a href="#!" @click.prevent="resendConfirmation">erneut zusenden</a> lassen.</span>
      </p>
    </div>
  </Card>
</template>

<script>
import Card from "@/components/Card.vue";
import { actions, http, pages, routes } from "@/constants.js";

export default {
  name: "RegisterConfirm",
  computed: {
    email() {
      return this.$route.query.email;
    },
    token() {
      return this.$route.query.token;
    }
  },
  methods: {
    async resendConfirmation() {
      const result = await this.$store.dispatch(actions.API_REQUEST, {
        route: routes.ACCOUNT.REGISTER.CONFIRM_RESEND,
        method: http.POST,
        body: { email: this.email },
        onError: () => this.$toast.error("Bestätigungs-Email konnte nicht gesendet werden. Bitte versuchen Sie es später erneut.")
      });

      if (result === null) return;

      this.$toast.success("Bestätigungs-E-Mail erfolgreich gesendet");
      this.$router.replace(pages.REGISTER_CONFIRM);
    }
  },
  async mounted() {
    if (!this.token) return;

    const result = await this.$store.dispatch(actions.API_REQUEST, {
      route: routes.ACCOUNT.REGISTER.CONFIRM,
      method: http.POST,
      body: { token: this.token },
      errorMessage: "Der Account konnte nicht aktiviert werden: "
    });

    if (result === null) return this.$router.replace(pages.LOGIN);
    this.$toast.success("Account bestätigt. Sie können sich nun einloggen.");
    this.$router.replace(pages.LOGIN);
  },
  components: {
    Card
  }
};
</script>

<style module type="scss">

</style>
